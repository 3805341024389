@import "./configs/color.css";

@media only screen and (max-width: 768px) {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Bootstrap table */
.react-bs-container-body {
    overflow-x: hidden;
}
.react-bs-container-body {
    td {
        white-space: normal !important;
    }
}
.action {
    display: flex;
    height: 2rem;
    width: 2rem;
    justify-content: center;
    align-items: center;
}
.react-bs-table {
    border: none !important;
    thead {
        padding: 0.2rem !important;
        color: white;
        background-color: $azul-oscuro !important;
    }
    th {
        padding: 0.5rem 0.2rem !important;
        text-transform: uppercase;
        font-weight: bold !important;
        font-size: 14px !important;
    }
    th:first-child {
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
    }
    th:last-child {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
    }
    th, td {
        border: none !important;
    }
    td {
        font-weight: 500 !important;
        font-size: 14px !important;
        color: $gris-oscuro !important;
        padding: 0.5rem 0 !important;
    }
    tbody {
        tr {
            border: 2px solid #D6D6D6 !important;
        }
        tr:nth-child(2n + 1) {
            background-color: white !important;
        }
        tr:nth-child(2n) {
            background-color: $gris-claro;
        }
        tr:hover {
            background-color: #FFD1A4 !important;
            .action {
                margin: 0 0.2rem;
            }
            .fas {
                color: white !important;
            }
            .action-marron-claro {
                background-color: $marron-claro !important;
                border-radius: 7px;
            }
            .action-azul-oscuro {
                background-color: $azul-oscuro !important;
                border-radius: 7px;
            }
            .action-gris {
                background-color: $gris !important;
                border-radius: 7px;
            }
            .action-celeste-oscuro {
                background-color: $celeste-oscuro !important;
                border-radius: 7px;
            }
            .action-azul-gris-oscuro {
                background-color: $azul-gris-oscuro !important;
                border-radius: 7px;
            }
        }
    }
}
.react-bs-table-pagination {
    .row {
        div:first-child {
            display: none !important;
        }
        div:last-child {
            display: flex !important;
        }
    }
}

@media only screen and (max-width: 768px) {
    .responsivo .react-bs-container-body {
        overflow-x: scroll !important;
    }
    .react-bs-table-container.responsivo .table {
        min-width: 900px !important;
    }
}

.scroll-table .react-bs-container-body  {
    overflow-x: scroll !important;
}

.react-bs-table-container.scroll-table .table {
    min-width: 5000px !important;
}