@import "../configs/breakpoints.css";

.header__container{
    display: flex;
    flex: 1;
    flex-direction: row;
    position: sticky;
    z-index: 6000;
    background-color: #0A1F40;
    height: 60px;
    top: 0;
}
.header-buscador-responsivo{
    display: flex;
    justify-content: start;
    flex: 1;
    align-items: center;
}
.header__logo{
    display: flex;
    justify-content: start;
    flex: 1;
    margin-left: 2rem;
    align-items: center;
}
.header__logo img{
    width: 8rem;
}

.logo-responsivo {
    width: 1.5rem !important;
}
.header__search{
    display: flex;
    flex: 3;
    flex-direction: row;
    align-items: center;
}
.header__search div  input{
    height:40px !important;
}
/* .header__search span {
    text-transform: uppercase;
} */
.header__side{
    display: flex;
    flex: 2;
    flex-direction: row;
    justify-content: space-evenly;
    margin-right: 25px;
    align-items: center;
}

.header__side .icon{
    margin-inline: 20px;
}
.header__side a{
    color: #FFFFFF!important;
}

.ant-dropdown {
    z-index: 102001 !important;
}

@media (max-width: $md) {
    .header__logo {
        margin-left: 1rem;
    }
    .header__search{
        flex: 2;
    }
    .header__side{
        flex: 5;
        justify-content: flex-end;
        margin-right: 10px;
    }

    .header__container .text-md {
        font-size: 1rem !important;
    }
}

@media (max-width: $md) {
    .header__sx__index{
        z-index: 1000 !important;
    }

}
@media (max-width: $lg){
    .header__side{
        margin-right: 10px;

    }
}