@import "../configs/color.css";
/* COMPONENTE DE IMAGE CROP */
.btn-image-picker {
    border: 1px solid transparent;
    border-radius: 0.75rem;
    padding: 0.4rem 2rem;
    font-size: 0.75rem;
    line-height: 1.125;
    font-weight: bold;
    font-family: "CatamaranLat", sans-serif;
    justify-content: center;
    z-index: 1000;
    color: #fff;
    background-color: #686a75;
    border-color: #686a75;
}

.image-input {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: $blanco;
}

.avatar-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-upload-select-picture-card {
    border-radius: 10px !important;
    margin: 0 !important;
    height: 150px !important;
    width: 100% !important;
    background-color: $blanco!important;
}

.ant-upload-list-picture-card-container {
    height: 150px !important;
    width: 150px !important;
    margin: 0 !important;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
    border: 2px solid;
    border-radius: 5px;
    border-color: $gris;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    border-radius: 5px;
}
.ant-upload {
    color: #363c3f;
}

.ant-upload-list-item {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

/** Formulario **/
.form-control {
    font-family: "Lato" !important;
    display: block;
    width: 100%;
    height: 2.5rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.938rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: $azul-gris-oscuro !important;
    background-color: $blanco!important;
    background-clip: padding-box;
    border: 0.125rem solid $gris !important;
    border-radius: 0.313rem;
    -webkit-transition: border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control-inline {
    height: 2.5rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.938rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: $gris !important;
    background-color: $blanco;
    background-clip: padding-box;
    border: 2px solid $gris;
    border-radius: 0.313rem;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        -webkit-transition: none;
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: initial;
    border: 0;
}

.form-control:focus {
    border-color: $marron-claro !important;
    outline: 0;
    box-shadow: unset !important;
}

.form-control:focus,
.form-control:focus:hover {
    border-color: $marron-claro !important;
    outline: 0;
    box-shadow: unset !important;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e2e2e2 !important;
    opacity: 2 !important;
}

.form-control:disabled:hover,
.form-control[readonly]:hover {
    border: 1px solid $marron-claro !important;
}

/*CAMPO INVALIDO*/
.invalid-feedback {
    color: $marron !important;
}
.is-invalid {
    border-color: $marron !important;
}

/* SELECTS */
/*Items del select*/

.select-kpis .react-select-container {
    width: 100%;
}
.css-kj6f9i-menu {
    z-index: 8000 !important;
    font-size: 13px !important;
}

.css-bg1rzq-control,
.css-1ajl57w-control {
    border-radius: 0.313rem !important;
    border: 0.125rem solid $gris !important;
    font-size: 0.938rem !important;
    height: 2.5rem !important;
    min-height: unset !important;
    .css-151xaom-placeholder {
        font-size: 0.938rem !important;
    }
}
.css-es53b3-multiValue {
    border-radius: 0.313rem !important;
}
.css-3pbdf8-control {
    border-radius: 0.313rem !important;
    border: 0.125rem solid $gris !important;
    font-size: 0.938rem !important;
}

.css-o5t5i1-control {
    border-radius: 0.313rem !important;
    border-color: $gris !important;
    box-shadow: 0 0 0 1px $gris !important;
}
/*Icono de expandible*/
.css-16pqwjk-indicatorContainer {
    color: $gris !important;
    padding: 0 8px 0 2px !important;
}
.css-1thkkgx-indicatorContainer {
    color: $gris !important;
    padding: 0 8px 0 2px !important;
}
/*Icono separador*/
.css-bgvzuu-indicatorSeparator {
    background-color: white !important;
}
/*Selecto cuando esta activo*/
.css-1szy77t-control,
.css-juf2xh-control {
    border-radius: 0.313rem !important;
    font-size: 0.938rem !important;
    height: 2.5rem !important;
    min-height: unset !important;
    border-color: $marron-claro !important;
    box-shadow: 0 0 0 2px $marron-claro !important;
}

/*Selecto cuando esta desactivado*/
.css-14ojxsb-control {
    border-radius: 0.313rem !important;
    border: 0.125rem solid #ced4da !important;
    font-size: 0.938rem !important;
    height: 2.5rem !important;
    min-height: unset !important;
    background-color: $gris !important;
}

/*El texto de adentro*/
.css-dvua67-singleValue {
    font-size: 0.938rem !important;
    color: $gris !important;
}
.css-ue83k8-singleValue {
    font-size: 0.938rem !important;
    color: $gris !important;
}
/* botones de adentro del select */
.css-16pqwjk-indicatorContainer:hover {
    .css-19bqh2r {
        color: $azul-oscuro !important;
    }
}

/* DAta Picker*/
.react-datepicker__input-container input {
    font-family: "Lato" !important;
    height: 2.5rem !important;
    color: $gris-oscuro;
    border-radius: 0.313rem !important;
    border: 0.125rem solid $gris !important;
    background-color: $blanco !important;
}
.react-datepicker__input-container input:focus {
    border: 0.125rem solid $marron-claro !important;
    border-color: $marron-claro !important;
}

.search-input {
    padding-left: 2rem !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    background-size: 20px 18px;
    background-position: 2% center;
    background-repeat: no-repeat;
    box-sizing: border-box;
}
.show {
    display: flex;
    background-color: $blanco;
    color:$gris-oscuro;
    border-radius: 0.313rem !important;
    box-sizing: border-box!important;
}

/* Checkbox and radio button */
/* Los colores con red aun no se saben de que son */
.c-checkbox,
.c-radio {
    margin-right: 4px;
}
.c-checkbox *,
.c-radio * {
    cursor: pointer;
}
.c-checkbox input,
.c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important;
}
.c-checkbox span,
.c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: sub;
    width: 20px;
    height: 20px;
    border: 2px solid $gris;
    text-align: center;
    border-radius: 5px;
}
.c-checkbox span:before,
.c-radio span:before {
    margin-left: 1px;
}

.c-radio:hover span {
    border: 2px solid $marron-claro;
}
.c-checkbox:hover span {
    border: 2px solid $marron-claro;
}
.form-inline .c-checkbox span,
.form-inline .c-radio span {
    margin-left: 0;
}
.c-checkbox.c-checkbox-rounded span,
.c-checkbox.c-radio-rounded span,
.c-radio.c-checkbox-rounded span,
.c-radio.c-radio-rounded span {
    border-radius: 500px;
}

/* override for radio */
.c-radio span {
    border-radius: 500px;
}

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    text-align: center !important;
    font-size: 13px;
    line-height: 18px;
    vertical-align: middle;
}

/* Checked state */
.c-checkbox,
.c-radio {
    /* override for radio */
    /* Disable state */
    /* override for radio */
}
.c-checkbox input[type="checkbox"]:checked + span:before,
.c-checkbox input[type="radio"]:checked + span:before,
.c-radio input[type="checkbox"]:checked + span:before,
.c-radio input[type="radio"]:checked + span:before {
    color: white;
    opacity: 1;
    transition: color 0.3s ease-out;
}
.lectura input[type="checkbox"]:checked + span:before,
.lectura input[type="radio"]:checked + span:before,
.lectura input[type="checkbox"]:checked + span:before,
.lectura input[type="radio"]:checked + span:before {
    color: $marron-claro;
    opacity: 1;
    transition: color 0.3s ease-out;
    cursor: initial;
}
.c-checkbox input[type="checkbox"]:checked + span,
.c-checkbox input[type="radio"]:checked + span,
.c-radio input[type="checkbox"]:checked + span,
.c-radio input[type="radio"]:checked + span {
    border-color: $marron-claro;
    background-color: $marron-claro;
}
.lectura input[type="checkbox"]:checked + span,
.lectura input[type="radio"]:checked + span,
.lectura input[type="checkbox"]:checked + span,
.lectura input[type="radio"]:checked + span {
    border-color: red !important;
    background-color: red !important;
    cursor: initial;
}
.lectura {
    cursor: initial !important;
}
.lec {
    border: 0px solid red !important;
}
.c-checkbox input[type="radio"]:checked + span,
.c-radio input[type="radio"]:checked + span {
    background-color: red;
}
.c-checkbox input[type="radio"]:checked + span:before,
.c-radio input[type="radio"]:checked + span:before {
    color: red;
}
.c-checkbox input[type="checkbox"]:disabled + span,
.c-checkbox input[type="radio"]:disabled + span,
.c-radio input[type="checkbox"]:disabled + span,
.c-radio input[type="radio"]:disabled + span {
    border-color: $gris-oscuro !important;
    /* background-color: $gris-claro !important; */
}
.c-checkbox input[type="radio"]:disabled + span,
.c-radio input[type="radio"]:disabled + span {
    background-color: red !important;
}
.c-checkbox input[type="radio"]:disabled + span:before,
.c-radio input[type="radio"]:disabled + span:before {
    color: red;
}

.c-radio.c-radio-nofont {
    /* override for radio */
    /* Disable state */
    /* override for radio */
}
.c-radio.c-radio-nofont span:before {
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 500px;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span:before {
    color: red;
    opacity: 1;
    transition: color 0.3s ease-out;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span {
    /* border-color: $azul-claro; */
    border: 2px solid $marron-claro;
    background-color: red;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span {
    background-color: white;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span:before {
    background-color: $marron-claro !important;
}
.c-radio.c-radio-nofont input[type="radio"]:disabled + span {
    border-color: $gris-oscuro !important;
    background-color: #ddd !important;
}
.c-radio.c-radio-nofont input[type="radio"]:disabled + span {
    background-color: #fff !important;
}
.c-radio.c-radio-nofont input[type="radio"]:disabled + span:before {
    background-color: white;
}

/* Switch */
.react-switch-bg {
    border: solid 1px white !important;
    background: $gris-claro !important;
}
.react-switch-handle {
    background: $marron-claro !important;
}
.react-switch-handle[aria-checked="false"] {
    background: $gris-oscuro !important;
}

/* Checkbox and radio button */
/* Los colores con red aun no se saben de que son */
.c-checkbox,
.c-radio {
    margin-right: 4px;
}
.c-checkbox *,
.c-radio * {
    cursor: pointer;
}
.c-checkbox input,
.c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important;
}
.c-checkbox span,
.c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: sub;
    width: 20px;
    height: 20px;
    border: 2px solid $gris;
    text-align: center;
    border-radius: 5px;
}
.c-checkbox span:before,
.c-radio span:before {
    margin-left: 1px;
}

.c-radio:hover span {
    border: 2px solid $marron-claro;
}
.c-checkbox:hover span {
    border: 2px solid $marron-claro;
}
.form-inline .c-checkbox span,
.form-inline .c-radio span {
    margin-left: 0;
}
.c-checkbox.c-checkbox-rounded span,
.c-checkbox.c-radio-rounded span,
.c-radio.c-checkbox-rounded span,
.c-radio.c-radio-rounded span {
    border-radius: 500px;
}

/* override for radio */
.c-radio span {
    border-radius: 500px;
}

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    text-align: center !important;
    font-size: 13px;
    line-height: 18px;
    vertical-align: middle;
}

/* Checked state */
.c-checkbox,
.c-radio {
    /* override for radio */
    /* Disable state */
    /* override for radio */
}
.c-checkbox input[type="checkbox"]:checked + span:before,
.c-checkbox input[type="radio"]:checked + span:before,
.c-radio input[type="checkbox"]:checked + span:before,
.c-radio input[type="radio"]:checked + span:before {
    color: white;
    opacity: 1;
    transition: color 0.3s ease-out;
}
.lectura input[type="checkbox"]:checked + span:before,
.lectura input[type="radio"]:checked + span:before,
.lectura input[type="checkbox"]:checked + span:before,
.lectura input[type="radio"]:checked + span:before {
    color: $marron-claro;
    opacity: 1;
    transition: color 0.3s ease-out;
    cursor: initial;
}
.c-checkbox input[type="checkbox"]:checked + span,
.c-checkbox input[type="radio"]:checked + span,
.c-radio input[type="checkbox"]:checked + span,
.c-radio input[type="radio"]:checked + span {
    border-color: $marron-claro;
    background-color: $marron-claro;
}
.lectura input[type="checkbox"]:checked + span,
.lectura input[type="radio"]:checked + span,
.lectura input[type="checkbox"]:checked + span,
.lectura input[type="radio"]:checked + span {
    border-color: red !important;
    background-color: red !important;
    cursor: initial;
}
.lectura {
    cursor: initial !important;
}
.lec {
    border: 0px solid red !important;
}
.c-checkbox input[type="radio"]:checked + span,
.c-radio input[type="radio"]:checked + span {
    background-color: red;
}
.c-checkbox input[type="radio"]:checked + span:before,
.c-radio input[type="radio"]:checked + span:before {
    color: red;
}
.c-checkbox input[type="checkbox"]:disabled + span,
.c-checkbox input[type="radio"]:disabled + span,
.c-radio input[type="checkbox"]:disabled + span,
.c-radio input[type="radio"]:disabled + span {
    border-color: $gris-oscuro !important;
    /* background-color: $gris-claro !important; */
}
.c-checkbox input[type="radio"]:disabled + span,
.c-radio input[type="radio"]:disabled + span {
    background-color: red !important;
}
.c-checkbox input[type="radio"]:disabled + span:before,
.c-radio input[type="radio"]:disabled + span:before {
    color: red;
}

.c-radio.c-radio-nofont {
    /* override for radio */
    /* Disable state */
    /* override for radio */
}
.c-radio.c-radio-nofont span:before {
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 500px;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span:before {
    color: red;
    opacity: 1;
    transition: color 0.3s ease-out;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span {
    /* border-color: $azul-claro; */
    border: 2px solid $marron-claro;
    background-color: red;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span {
    background-color: white;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span:before {
    background-color: $marron-claro !important;
}
.c-radio.c-radio-nofont input[type="radio"]:disabled + span {
    border-color: $gris-oscuro !important;
    background-color: #ddd !important;
}
.c-radio.c-radio-nofont input[type="radio"]:disabled + span {
    background-color: #fff !important;
}
.c-radio.c-radio-nofont input[type="radio"]:disabled + span:before {
    background-color: white;
}

/* Switch */
.react-switch-bg {
    border: solid 1px white !important;
    background: $gris-claro !important;
}
.react-switch-handle {
    background: $marron-claro !important;
}
.react-switch-handle[aria-checked="false"] {
    background: $gris-oscuro !important;
}

.fade.show {
    min-width: 2rem !important;
    max-width: 15rem !important;
    width: auto !important;
    z-index: 1000;
}
.question-button{
    color: $blanco;
    background-color: $azul;
    border-radius: 3px;
    height: 1.7rem;
    width:1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

/*Progress bar*/
.ant-progress-text {
    display: none;
}
.ant-progress-outer{
    margin-right: 0!important;
    padding-right: 0.5rem!important;
    width: 4rem!important;
}

.progress__bar{
    width: 7.2rem;
    float: right;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ant-progress-inner{
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    height: 0.32rem;
    margin-bottom: 0.35rem;

}

.ant-upload-list{
    width: 100%!important;
    height: 100%!important;
}

.ant-progress-status-success .ant-progress-bg {
    background-color: #AF5555!important;
}
.ant-progress-bg {
    background-color: #538BAF!important;
}


/*Broder radios */

.border__radius{
    border-radius: 0.625rem;
}

/*Editar Comentarios*/
.editar-area {
    font-family: "Lato" !important;
    display: block;
    width: 100%;
    height: 2.5rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.938rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: $gris-oscuro !important;
    background-color: $blanco!important;
    background-clip: padding-box;
    border: 0.1rem solid $gris !important;
    border-radius: 0.313rem;
    -webkit-transition: border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.editar-area:focus {
    border-color: $marron-claro !important;
    outline: 0;
    box-shadow: unset !important;
}

.editar-area:focus,
.editar-area:focus:hover {
    border-color: $marron-claro !important;
    outline: 0;
    box-shadow: unset !important;
}


/*Editar Post*/
.editar-area-post {
    font-family: "Lato" !important;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.938rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: $gris-oscuro !important;
    background-color: $blanco!important;
    background-clip: padding-box;
    border: 0.1rem solid $gris;
    border-radius: 0.313rem;
    -webkit-transition: border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.editar-area-post:focus {
    border-color: $marron-claro !important;
    outline: 0;
    box-shadow: unset !important;
}

.editar-area-post:focus,
.editar-area-post:focus:hover {
    border-color: $marron-claro !important;
    outline: 0;
    box-shadow: unset !important;
}
/* .ant-upload-list-picture-card-container {
    height: 15rem !important;
    width: 15rem !important;
} */


/* Antd checkbox */
.ant-checkbox:hover {
    border-color: $marron-claro !important;
}

.ant-checkbox-inner {
    background-color: white !important;
    border-color: $marron-claro !important;
}

.ant-checkbox-checked {
    .ant-checkbox-inner {
        background-color: $marron-claro !important;
    }
}

/* WYSIWYG EDITOR */
.editor-container {
    border: 1px solid $gris;
    border-radius: 0.25rem;
}

.public-DraftStyleDefault-block span span, .public-DraftStyleDefault-block span{
    font-size: 100%;
}

.editor-toolbar {
    background-color: white;
    border: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom: 2px solid $gris-claro;

}

.editor-toolbar.disabled{
    display: none !important;
}

.editor-content {
    padding-left:  0.8rem;
    padding-right: 0.8rem;
    height: 20rem;
}

.DraftEditor-root {
    height: 95% !important;
}

.rdw-link-modal {
    height: 190pt;
}

.rdw-link-modal-buttonsection {
    .rdw-link-modal-btn {
        border: 1px solid transparent;
        border-radius: 0.75rem;
    }
}

.rdw-option-wrapper {
    border: none !important;
    height: 2rem;
    padding: 0;
    margin: 0px 6px;
    img {
        width: 2.5rem;
    }
}

.rdw-option-wrapper:hover {
    box-shadow: none;
}

.rdw-option-active {
    box-shadow: none;
    border: 1px solid $azul-oscuro !important;
}

.rdw-link-modal-label {
    color: $marron;
}

.rdw-colorpicker-modal {
    font-size: 12px !important;
    height: 150pt;
}

.rdw-colorpicker-modal-style-label {
    font-size: 12px;
    padding: 0px;
}

.rdw-colorpicker-modal-options {
    overflow: auto;
}

.rdw-colorpicker-option {
    width: 1rem;
    height: 1rem;
}

/* Componente para multiples archivos */
.input-archivo {
    display: block;
    width: 100%;
    height: 2.5rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.938rem !important;
    font-weight: 400;
    line-height: 1.7;
    color: $azul-gris-oscuro !important;
    background-color: $blanco!important;
    background-clip: padding-box;
    border: 0.125rem solid $gris !important;
    border-radius: 0.313rem;
    -webkit-transition: border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    overflow: hidden;
}

/* DATE PICKER */
.date-container {
    padding-right: 0 !important;

    .col-11{
        padding-right: 0 !important;
    }
    .col-1 {
        justify-content: start !important;
    }
    @media (max-width: $sm) {
        padding-left: 0 !important;

        .col-11{
            padding-left: 0 !important;
        }
        .col-1 {
            justify-content: center !important;
        }
    }
}
.day-picker-profesores{
    padding-left: 0 !important;
    padding-right: 0 !important;

    .col-md-12{
        padding-right: 0 !important;
    }

    @media (max-width: $sm) {
        .col-md-12{
            padding-left: 0 !important;
        }
    }
}

/* TIME PICKER */
.time-container {
    padding-right: 0 !important;

    .col-11{
        padding-right: 0 !important;
    }
    .col-1 {
        justify-content: start !important;
    }
    @media (max-width: $sm) {
        padding-left: 0 !important;

        .col-11{
            padding-left: 0 !important;
            .col-md-12{
                padding-right: 0 !important;
                padding-left: 0 !important;

                .time-picker-profesores{
                    padding-right: 0 !important;
                    padding-left: 0 !important;

                    .col-12 {
                        padding-right: 0 !important;
                        padding-left: 0 !important;
                    }
                }
            }
        }
        .col-1 {
            justify-content: center !important;
        }
    }
}
.time-picker-profesores {
    padding-right: 0 !important;
    @media (max-width: $sm) {
        padding-right: 0 !important;
        padding-left: 0 !important;

        .col-12{
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }
}
.react-time-picker{
    width: 100% !important;
}
.react-time-picker__wrapper{
    height: 2.5rem !important;
    width: 100% !important;
    color: $azul-gris-oscuro !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid $gris;
    border-radius: 0.313rem;
}
.react-time-picker__inputGroup{
    justify-content: center;
    input {
        width: 30% !important;
        font-size: 15px;
        text-align: center;
        color: $gris-oscuro !important;
    }
    span {
        width: 30% !important;
        font-size: 15px;
        text-align: center;
    }
}
.react-time-picker__inputGroup__input {
    /* color: $gris-dark; */
}
.react-time-picker__inputGroup__leadingZero{
    display: none !important;
}
.react-time-picker__inputGroup__hour{
    min-width: 20px;
    justify-content: center;
    @media (max-width: $sm) {
        min-width: 25px;
    }
}
.react-time-picker__inputGroup__input:focus {
    outline: $marron-claro solid 2px !important;
}
.react-time-picker__inputGroup__amPm option:focus{
    outline: $marron-claro solid 2px !important;
}
