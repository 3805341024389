@import '../configs/color.css';

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
}

.ant-tabs-tab {
    flex: 1;
    margin: 0px;
    justify-content: center;
}

.ant-tabs-tab {
    background-color:$celeste-oscuro;
    color:$blanco;
    padding: 0.3rem 0;
    border: 1px solid $celeste-oscuro;
}

.ant-tabs-tab.ant-tabs-tab-active {
    background-color: $blanco;
    color: $celeste-oscuro !important;
}

.ant-tabs-tab-btn {
    font-size: 14pt;
    font-family: "Lato";
    padding-left: 0.3rem;
    color:$blanco; ;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $celeste-oscuro;
    background-color: $blanco;
    font-weight: bold;
    font-size: 14pt;
    font-family: "Lato";
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar{
    height: 3px;
}

.tab-mitad .ant-tabs-nav {
    width: 50%;
}

@media (max-width: $md) {
    .tab-mitad .ant-tabs-nav {
        width: 100%;
    }
}
.ant-tabs-nav-operations{
    display: none !important;
}
.ant-tabs-nav-operations-hidden{
    display: none !important;
}
.ant-tabs-nav-operations.ant-tabs-nav-operations-hidden{
    display: none !important;
}


/* ANT PAGINATION */
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    border: none;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-pagination .anticon {
    font-size: 18px;
    color: $azul-oscuro;
}

.ant-pagination-disabled .anticon {
    color: $gris;
}

.ant-pagination-item {
    border: none;
    font-family: "Lato";
    font-weight: bold;
    font-size: 16px;
    color: $gris-oscuro;
}

.ant-pagination-item-active {
    font-weight: bold;
    color: white;
    border-radius: 20px;
    background-color: $azul-oscuro;
}
