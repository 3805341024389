@import '../configs/breakpoints.css';

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $gris-oscuro !important;
}

h1, .h1 {
    font-size: 30pt;
    font-weight: bold;
    margin-bottom: 0 !important;
    font-family: 'Lato'!important;
    @media (max-width: $md) {
        font-size: 30pt !important;
        line-height: 1.5rem !important;
    }
}
h2, .h2 {
    font-size: 25pt;
    font-weight: bold;
    margin-bottom: 0 !important;
    font-family: 'Lato'!important;
}
h3, .h3 {
    font-size: 20pt;
    font-weight: bold;
    margin-bottom: 0 !important;
    font-family: 'Lato'!important;
}
h4, .h4 {
    font-size: 15pt;
    margin-bottom: 0 !important;
    font-family: 'Lato'!important;
}
h5, .h5 {
    font-size: 13pt;
    margin-bottom: 0 !important;
    font-family: 'Lato'!important;
}
label, .label {
    font-size: 13px;
    font-weight: bold;
    padding-left: 5px !important;
    font-family: 'Lato'!important;
    color:$azul-gris-oscuro;

}
span {
    font-size: 13px;
}

.tachado {
    text-decoration: line-through;
}
.tachado-touch {
    text-decoration: line-through;
    cursor: pointer;
}
.text-touch{
    cursor: pointer;
}
.bold{
    font-weight: bold;
}

.light{
    font-weight: lighter;
}

.regular{
    font-weight: 400;
}

.medium{
    font-weight: 500;
}

.text-sm{
    font-size: 1rem;
}

.text-md{
    font-size: 1.5rem;
}
.text-md2{
    font-size: 18pt;
}
.text-lgs{
    font-size: 1.8rem;
}

.text-lg{
    font-size: 20pt;
    @media (max-width: $md) {
        font-size: 16pt !important;
    }
}

.text-xxsm {
    font-size: 0.5rem;
}
.text-xs {
    font-size: 0.8rem;
}

.text-sm {
    font-size: 1rem;
}

.text-qts {
    font-size: 1.3rem;
}

.text-equal {
    font-size: 13px;
}

.text-equal2 {
    font-size: 12px;
}

.text-xsm {
    font-size: 11px !important;
}

a{
    text-decoration: none !important;
}

/* TAGS ANTD */
.ant-tag {
    border-radius: 0.2rem;
    padding: 0.1rem 0.5rem;
    opacity: 1;
    border: none;
}
.ant-tag-success {
    color: #539241;
    background: #E0FFD6;
}
.ant-tag-processing {
    color: #1446A0;
    background: #D5E4FE;
}
.ant-tag-error {
    color: #AF5555;
    background: #FFE1E1;
}
.ant-tag-warning {
    color: #FFF;
    background: #88B2FC;
}
.ant-tag:hover {
    opacity: 1;
}

