
@import "../configs/color.css";

/*Notificaciones*/
.titulo {
    margin-bottom: 0.4rem;
}

.notifications-tr {
    z-index: 107005 !important;
}

.notification::before {
    color: white !important;
}

.notification-info:before {
    display: none !important;
}

.notification-info {
    background-color: white !important;
    box-shadow: none !important;
    padding: 5px !important;
    border: 2px solid $azul-oscuro !important;
}

.notification-icono {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;

}

.push-icono {
    width: 28px;
}

.noti-min-heigth {
    min-height: 70px;
}

.notification-body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
        font-weight: bold;
        font-size: 15px !important;
        color: $azul-oscuro !important;
    }
}

.notification-dismiss {
    background-color: $marron !important;
    width:  18px !important;
    height: 18px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px !important;
}
