/* CSS de Node Modules */
@import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
@import "../../node_modules/react-day-picker/lib/style.css";
@import "../../node_modules/antd/dist/antd.css";
@import "../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../../node_modules/react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
@import "bootstrap_reset.css";
/*Importando estilos para rc-tabs*/
@import "../../node_modules/rc-tabs/assets/index.css";
@import "./rc-tabs.css";
/* Configuraciones */
@import "configs/color.css";
@import "configs/breakpoints.css";
/* ALL */
@import "all.css";
@import "all2.css";
@import "styles.css";
/* Componentes */
@import "./components/buttons.css";
@import "./components/cards.css";
@import "./components/date-picker.css";
@import "./components/form.css";
@import "./components/texto.css";
@import "./components/modal.css";
@import "./components/wizard.css";
@import "./components/sidebar.css";
@import "./components/SearchNavbar.css";
@import "./components//header.css";
@import "./components/navbarBack.css";
@import "./components/tablero.css";
@import "./components/collapse.css";
@import "./components/ant-tabs.css";
@import "./components/notifications.css";
@import "./components/fcm.css";
@import "./components/sliderAnt.css";
@import "./components/grid.css";
/* Fuente (FONT) */
@import url("https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext");

.container {
    font-family: "Lato", Helvetica, Arial, sans-serif !important;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
}
@media only screen and (max-width: 768px) {
    .container {
        margin-left: 0;
        margin-right: 0;
    }
}

.content-wrapper {
    position: absolute;
    top: 75px;
    width: 100%;
    background: #f5f6f8;
}

body {
    font-family: "Lato", Helvetica, Arial, sans-serif !important;
    background: transparent linear-gradient(180deg, #e4e1e1 0%, #c8c8c8 1000%) 0%
        0% padding-box;
    /* background-color: #C8C8C8; */
    color: $gris-oscuro !important;
    font-size: 13px;
}

/* Scroll */
::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 12px !important;
    background-color: #FFF !important;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-color: transparent !important;
    display: block !important;
    background-image: url("../assets/img/iconos/arrow_up.png") !important;
    background-position: center !important;
    background-size: 20px !important;
    background-repeat: no-repeat !important;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
    background-color: transparent !important;
    display: block !important;
    background-image: url("../assets/img/iconos/arrow_down.png") !important;
    background-position: center !important;
    background-size: 20px !important;
    background-repeat: no-repeat !important;
}

::-webkit-scrollbar:horizontal {
    height: 10px !important;
    background-color: #FFF !important;
}

::-webkit-scrollbar-thumb {
    background-color: #8C8F9B !important;
    border-radius: 20px !important;
    border: 2px solid #FFF !important;
}

::-webkit-scrollbar-track {
    border-radius: 10px !important;
}


/*Style preloader*/
.loader-container {
    min-height: 140px;
}
.loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 60px;
    height: 60px;
    left: 50%;
    background-color: #e0e0e0;
    position: absolute;
    margin: 1rem 1rem 1rem -30px;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
}
.small-loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    position: absolute;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
    margin: 1rem 1rem 1rem -30px;
    left: 40%;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* fallback */
@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
        format("woff2");
}

.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}
#main-logo {
    max-width: 156px;
}

.error-template {
    padding: 40px 15px;
    text-align: center;
}
.error-actions {
    margin-top: 15px;
    margin-bottom: 15px;
}
.error-actions .btn {
    margin-right: 10px;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

/*Sobre-escribiendo z-index para el swal*/
.swal2-container {
    z-index: 999999;
}

.z-index{
  z-index: -1;
}
