.perfil-portada {
    border-radius: 0.3rem;
    border: 2px solid white;
    width: 100%;
    height: 20vh;
    background-color: aqua;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.btn-editar-perfil {
    float: right;
}

.perfil-avatar-img {
    position: absolute;
    top: 13vh;
    /* bottom: 0; */
    height: 8rem;
    width:  8rem;
    border-radius: 50%;
    border: 2px solid #D6D6D6;
    background-color: white;
}

.link-perfil {
    color: $celeste-oscuro;
}
.link-perfil:hover {
    color: $azul-oscuro;
}

.columna-intereses {
    flex: 2;
    justify-content: center;
    align-items: start;
}

.action {
    display: flex;
    align-items: center;
}

.fas {
    font-size: 17px;
}

.circulo-icono {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    border: 2px solid;
    border-radius: 50%;
    /* padding: 0.4rem; */
    width: 2rem;
    height: 2rem;
}

.max-width-100 {
    max-width: 100%;
}

.borde-progress {
    .ant-progress-inner {
        border: 1px solid #363C3F;
    }
}

.circulo-puntos {
    display: flex;
    background-color: $azul;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width:  3rem;
    height: 3rem;
}

.logo-tabla {
    border-radius: 50%;
    border: 1px solid #363C3F;
    padding: 0.25rem;
    width:  1.8rem;
    height: 1.8rem;
}

/* Iconos swal */
.swal2-icon {
    span {
        font-size: 16px !important;
    }
}

/* Whatsapp Button */
.whatsapp button{
    padding: 0;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
    border: none;
    box-shadow: none;
}
.whatsapp button:focus {
    outline: 0;

}

/* Filtro cohorte */
.filtro-cohortes {
    width: 10rem;
    background-color: transparent;
}
.filtro-cohortes .css-bg1rzq-control,
.filtro-cohortes .css-juf2xh-control,
.filtro-cohortes .css-1szy77t-control {
    background-color: transparent;
    border: none !important;
    box-shadow: none !important
}
.filtro-cohortes .css-dvua67-singleValue,
.filtro-cohortes .css-1g6gooi,
.filtro-cohortes .css-151xaom-placeholder,
.filtro-cohortes .css-16pqwjk-indicatorContainer:hover .css-19bqh2r {
    color: white !important;
}

.sin-padding label {
    margin-bottom: 0 !important;
}

.ant-dropdown-link {
    font-size: 15px;
}

@media (max-width: $sm) {
    .social-layout {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media (max-width: $md) {
    .layout-tabla {
        max-width: 100%;
        overflow-x: scroll;
    }

    .perfil-datos {
        margin-top: 4.5rem;
    }

    .w-100-responsivo {
        width: 100% !important;
    }

    .w-70-responsivo {
        width: 70% !important;
    }

    .filtro-cohortes {
        width: 100%;
    }
}

.border__redondos{
    border: 1px solid #707070;
    border-radius: 5px;
}
