.inner-addon {
    position: relative;
}
.inner-addon .glyphicon {
    position: absolute;
    padding: 8px;
    pointer-events: none;
}
.left-addon .glyphicon {
    left: 0px;
}
.right-addon .glyphicon {
    right: 0px;
}
.left-addon input {
    padding-left: 30px;
}
.right-addon input {
    padding-right: 30px;
}
.glyphicon {
    height: 40px!important;
}

/* Panel de resultados previos */
.resultados-buscador {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    width: 100%;
    overflow-y: auto;
    max-height: 90vh;
    position: absolute;
    z-index: 999999;
    border-radius: 0.3rem;
    margin-top: 0.2rem;
    border: 1px solid $gris;
}

.texto-cortado {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


@media (max-width: $md) {
    .texto-cortado {
        width: 12rem !important;
    }
}

@media (max-width: $sm) {
    .texto-cortado {
        width: 8rem !important;
    }
}
