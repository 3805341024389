
.dropdown__date__picker{
    display: flex;
    flex-direction:row;
    justify-content: space-between;

}
.dropdown__select{
    display: flex;
    flex-direction: column;
}

#months{
    padding-inline: 0.5rem;
    padding-block: 0.25rem;
    font-family: 'Lato'!important;
    background-color: white !important;
    border-radius: 0.313rem !important;
    border: 0.125rem solid #8C8F9B !important;
    font-size: 0.938rem !important;
    height: 2.5rem !important;
    min-height: unset !important;
    
}

#days{
    padding-inline: 0.5rem;
    padding-block: 0.25rem;
    background-color: white !important;
    border-radius: 0.313rem !important;
    border: 0.125rem solid #8C8F9B !important;
    font-size: 0.938rem !important;
    height: 2.5rem !important;
    min-height: unset !important;
}
#years{
    padding-inline: 0.5rem;
    padding-block: 0.25rem;
    background-color: white !important;
    border-radius: 0.313rem !important;
    border: 0.125rem solid #8C8F9B !important;
    font-size: 0.938rem !important;
    height: 2.5rem !important;
    min-height: unset !important;
}
/* b2d4ff*/
#months:focus{
    border-color: #BE7D3C !important;
    outline: none !important;

}
#months option:checked {
    background-color:#2684ff;
    color: #ffffff;
}
#months option:hover {
    background-color: #b2d4ff  !important;
}
#days:focus{
    border-color: #BE7D3C !important;
    outline: none !important;

}
#days option:checked {
    background-color:#2684ff;
    color: #ffffff;
}
#days option:hover {
    background-color: #b2d4ff  !important;
}
#years:focus{
    border-color: #BE7D3C !important;
    outline: none !important;

}
#years option:checked {
    background-color:#2684ff;
    color: #ffffff;
}
#years option:hover {
    background-color: #b2d4ff  !important;
}
@media(max-width:576px){
    #months{
        padding-inline: 0.15rem;
        padding-block: 0.15rem;
        font-family: 'Lato'!important;
        background-color: white !important;
        border-radius: 0.313rem !important;
        border: 0.125rem solid #8C8F9B !important;
        font-size: 0.80rem !important;
        height: 2.5rem !important;
        min-height: unset !important;
        
    }
    
    #days{
        padding-inline: 0.15rem;
        padding-block: 0.15rem;
        background-color: white !important;
        border-radius: 0.313rem !important;
        border: 0.125rem solid #8C8F9B !important;
        font-size: 0.80rem !important;
        height: 2.5rem !important;
        min-height: unset !important;
    }
    #years{
        padding-inline: 0.15rem;
        padding-block: 0.15rem;
        background-color: white !important;
        border-radius: 0.313rem !important;
        border: 0.125rem solid #8C8F9B !important;
        font-size: 0.80rem !important;
        height: 2.5rem !important;
        min-height: unset !important;
    }

}