/* Colores base */
$color-title: #721c24;
$color-navbar: #006FBD;
$primary: #007bff;

/************/
$azul: #1446A0;
$azul-oscuro:#0A1F40;
$azul-gris-oscuro:#363C3F;
$celeste: #66C4FF;
$amarillo: #FDCA40;
$cafe: #844F39;
$cafe-oscuro:#54311A;
$gris-oscuro-2:#BEBEBE;
$gris-claro:#E6E6E6;
$gris:#8C8F9B;
$gris-oscuro:#5F5F5F;
$verde:#539241;
$naranja:#FF9D3B;
$marron: #AF5555;
$marron-claro:#BE7D3C;
$celeste-oscuro:#538BAF;
$blanco:#FFFFFF;
$amarillo-palido:#FFF2E5;

$facebook: #1560D1;
$google: #CB2727;

/* Clases de colores para texto */
.azul{
    color: $azul;
}
.azul-oscuro{
    color: $azul-oscuro;
}
.azul-gris-oscuro{
    color: $azul-gris-oscuro;
}
.celeste{
    color: $celeste;
}
.amarillo{
    color: $amarillo;
}
.cafe{
    color: $cafe;
}
.cafe-oscuro{
    color: $cafe-oscuro;
}
.gris-claro{
    color: $gris-claro;
}
.gris{
    color: $gris;
}
.gris-oscuro{
    color: $gris-oscuro;
}
.gris-oscuro-2{
    color: $gris-oscuro-2;
}
.verde{
    color: $verde;
}
.naranja{
    color: $naranja;
}
.marron{
    color: $marron;
}
.marron-claro{
    color: $marron-claro;
}
.celeste-oscuro{
    color: $celeste-oscuro;
}
.blanco{
    color: $blanco !important;
}
.facebook{
    color: $facebook;
}
.google{
    color: $google;
}
/* Fondos de los colores */

.bg-azul{
    background-color: $azul;
}
.bg-azul-oscuro{
    background-color: $azul-oscuro;
}
.bg-azul-gris-oscuro{
    background-color: $azul-gris-oscuro;
}
.bg-gris-oscuro-2{
    background-color: $gris-oscuro-2!important;
}
.bg-celeste{
    background-color: $celeste;
}
.bg-amarillo{
    background-color: $amarillo;
}
.bg-cafe{
    background-color: $cafe;
}
.bg-cafe-oscuro{
    background-color: $cafe-oscuro;
}
.bg-gris-claro{
    background-color: $gris-claro;
}
.bg-gris{
    background-color: $gris;
}
.bg-gris-oscuro{
    background-color: $gris-oscuro;
}
.bg-verde{
    background-color: $verde;
}
.bg-naranja{
    background-color: $naranja;
}
.bg-marron{
    background-color: $marron;
}
.bg-marron-claro{
    background-color: $marron-claro;
}
.bg-celeste-oscuro{
    background-color: $celeste-oscuro;
}
.bg-blanco{
    background-color: $blanco;
}
.bg-facebook{
    background-color: $facebook;
}
.bg-google{
    background-color: $google;
}
.bg-amarillo-palido{
    background-color: $amarillo-palido!important;
}


/* Colores de borde */
.borde-celeste-oscuro{
    border-color: $celeste-oscuro;
}
.borde-marron-claro{
    border-color: $marron-claro;
}
