
@import './configs/color.css';
.rc-tabs-nav-wrap {
    background-color: #f2f2f2;
}

.rc-tabs-tab-active, .rc-tabs-tab-active {
    color: #66C4FF !important;
}
.rc-tabs-ink-bar {
    background-color:#66C4FF;
}

.rc-tabs-top .rc-tabs-bar {
    border-bottom: none;
}
.rc-tabs-top .rc-tabs-tab {
    font-size: 18pt;
    font-weight: bold;
    margin: 0px;
    padding: 10px 50px 10px 50px;
    color: $blanco;
    max-width: 100%;
    border-bottom: 3px solid;
}
.rc-tabs-top .rc-tabs-bar div {
    display: flex !important;
    flex: 1;
}
.rc-tabs-tab:hover {
    color:$blanco;
}
.rc-tabs-top {
    border-bottom: none;
}

.rc-tabs-bottom .rc-tabs-tab {
  padding-left: 30px;
  padding-right: 30px;
}

.oscuro {
    .rc-tabs-nav-wrap {
        background-color: #5F5F5F;
    }

    .rc-tabs-top .rc-tabs-tab {
        color: #5F5F5F;
    }

    .rc-tabs-tab:hover {
        color: #5F5F5F;
    }

    .ant-tabs-tab-btn {
        color: #5F5F5F;
    }
}
