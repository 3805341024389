.tablero {
    display: flex;
    flex-direction: column;
    flex: 1;
    .RSPBprogressBar {
        height: 3px;
        background-color: $azul-gris-oscuro;
    }

    .ant-timeline-item-head {
        border: 2px solid #D6D6D6 !important;
        border-radius: 50%;
        padding: 0px !important;
    }

    .ant-timeline {
        width: 100%;
    }
}

.tablero-dot {
    width:  1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $azul;
    cursor: pointer;
}
.tablero-dot.activo, .tablero-dot:hover {
    background-color: $marron;
}

.tablero-dot-transparent {
    width:  1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
}
.tablero-dot-transparent.activo, .tablero-dot-transparent:hover {
    background-color: $marron;
}

.tablero-scala {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -35px;
    left: 35%;
}

.tablero-scala-linea {
    height: 20px;
    width: 2px;
    background-color: $gris;
}

.tablero-timeline {
    position: absolute;
    display: block;
    float: left;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 180%;
    left: 45%;
    width: 150px;
}

.tablero-linea-vertical {
    height: 2rem;
    width: 1px;
    background-color: #D6D6D6;
    position: absolute;
    left: 50%;
}
.tablero-linea-vertical.activo, .tablero-linea-vertical:hover {
    z-index: 4;
    background-color: $marron;
}

.dot-empresa {
    width: 1.5rem;
    border-radius: 50%;
}

.timeline-item-empresa {
    font-weight: bold;
    font-size: 16px;
    color: $gris-oscuro;
}

.timeline-item-dueño {
    font-size: 13px;
    color: white;
}

.tablero-timeline.activo, .tablero-timeline:hover {
    z-index: 5;
    .ant-timeline-item-head {
        border: 2px solid $marron !important;
    }

    .timeline-item-empresa {
        color: $marron;
    }
}

@media (max-width: $md) {
    .tablero {
        width: 85vw;

        .RSPBprogressBar {
            width: 250vw;
        }
    }

}
