@import "../configs/color.css";

.ant-collapse {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #363C3F;
    font-family: 'Lato'!important;
    list-style: none;
    background-color: $blanco;
    border: none;
}
.ant-collapse-content {
    /* overflow: hidden; */
    color: #363C3F;
    background-color: #fff;
    border-top: none;
}
.ant-collapse > .ant-collapse-item {
    border-bottom:0px !important;
}

.ant-collapse-header {
    background-color: $gris-claro
}
.ant-collapse-header .ant-collapse-arrow svg {
    display: inline-block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 1rem !important;
    left: unset;
    font-size: 15pt;
    /* top: 0.40rem; */
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 0px!important;
    box-sizing: border-box !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-top: .25rem;
    padding-right: 0px !important;
    padding-bottom: .25rem;
    padding-left: 0px;
}
.ant-collapse-item{
    margin-bottom: 0.5rem;
}
.collapse__panel{
    border-top: 3px solid  rgb(230,230,230,.5);
}
.collapse__logo{
    width:1.5rem;
    height:1.5rem;
    border:1px solid;
    border-radius: 100%;
    padding: 2px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    background-color: $azul-oscuro;
    border: 1px solid;
    border-radius: 100%;
    color:$blanco;
    padding: 2px;
}
