@import "../configs/color.css";

.buttons-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btn{
    border: 0.13rem solid transparent !important;
    border-radius: .19rem  !important;
    padding: 0.4rem 2rem !important;
    font-size: .75rem !important;
    line-height: 1.125 !important;
    font-weight: bold !important;
    font-family: 'normal', Lato !important;
    justify-content: center !important;
}

/* Botones solidos */
.btn-primary {
    color:$blanco;
    background-color: $marron-claro!important;
    border-color: $marron-claro!important;
}

.btn-primary:hover {
    color:$marron-claro!important;
    background-color: $blanco!important;
    border-color: $marron-claro!important;
    box-shadow: none !important;
}

.btn-primary:focus {
    color:$marron-claro!important;
    background-color: $blanco!important;
    border-color: $marron-claro!important;
    box-shadow: none !important;
}

.btn-primary:disabled {
    color: #fff;
    background-color: #3d8b01;
    border-color: #3d8b01;
    box-shadow: none !important;
}

.btn-secondary {
    color:$azul-oscuro!important;
    background-color: $blanco!important;
    border-color: $azul-oscuro!important;
    border: 2px solid $azul-oscuro;
}

.btn-secondary:hover {
    color: $blanco!important;
    background-color: $azul-oscuro!important;
    border-color: $azul-oscuro!important;
}

.btn-secondary:focus {
    color: $blanco!important;
    background-color: $azul-oscuro!important;
    border-color: $azul-oscuro!important;
    box-shadow: none !important;
}

.btn-option-one {
    color:$celeste-oscuro!important;
    background-color: $blanco!important;
    border-color: $celeste-oscuro!important;
}

.btn-option-one:hover {
    color:$blanco!important;
    background-color: $celeste-oscuro!important;
    border-color: $celeste-oscuro!important;
    box-shadow: none !important;
}

.btn-option-one:focus {
    color:$blanco!important;
    background-color: $celeste-oscuro!important;
    border-color: $celeste-oscuro!important;
    box-shadow: none !important;
}

.btn-option-two {
    color:$blanco!important;
    background-color: $gris!important;
    border-color: $gris!important;
}

.btn-option-two:hover {
    color:$gris!important;
    background-color: $blanco!important;
    border-color: $gris!important;
    box-shadow: none !important;
}

.btn-option-two:focus {
    color:$gris!important;
    background-color: $blanco!important;
    border-color: $gris!important;
    box-shadow: none !important;
}

/* OTROS */

.btn-option-one-fill {
    color: $blanco!important;
    background-color: $celeste-oscuro!important;
    border-color: $celeste-oscuro!important;
}

.btn-option-one-fill:hover {
    color:$celeste-oscuro!important;
    background-color: $blanco!important;
    border-color: $celeste-oscuro!important;
    box-shadow: none !important;
}

.btn-option-one-fill:focus {
    color:$celeste-oscuro!important;
    background-color: $blanco!important;
    border-color: $celeste-oscuro!important;
    box-shadow: none !important;
}

.btn-option-two-outline {
    color: $gris!important;
    background-color: $blanco!important;
    border-color: $gris!important;
}

.btn-option-two-outline:hover {
    color: $blanco!important;
    background-color: $gris!important;
    border-color: $gris!important;
    box-shadow: none !important;
}

.btn-option-two-outline:focus {
    color: $blanco!important;
    background-color: $gris!important;
    border-color: $gris!important;
    box-shadow: none !important;
}

.btn-secondary-fill {
    color: $blanco!important;
    background-color: $azul-oscuro!important;
    border-color: $azul-oscuro!important;
}

.btn-secondary-fill:hover {
    color: $azul-oscuro!important;
    background-color: $blanco!important;
    border-color: $azul-oscuro!important;
}

/* .btn-secondary-fill:focus {
    color: $azul-oscuro!important;
    background-color: $blanco!important;
    border-color: $azul-oscuro!important;
    box-shadow: none !important;
} */

.btn-danger-outline {
    color: $marron!important;
    background-color: $blanco!important;
    border-color: $marron!important;
}

.btn-danger-outline:hover {
    color: $blanco!important;
    background-color: $marron!important;
    border-color: $marron!important;
}

.btn-danger-outline:focus {
    color: $blanco!important;
    background-color: $marron!important;
    border-color: $marron!important;
}

.btn-delete, .btn-clear {
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
}
.btn-delete:focus, .btn-clear:focus, .btn-action:focus {
    outline: none !important;
}

.btn-action {
    border: none !important;
    padding: 0.2 !important;
    border-radius: 0.8rem;
    width: 2rem;
    height: 2rem;
}

/* Botones swal */
.btn-success {
    color: $blanco;
    background-color: $verde!important;
    border-color: $verde!important;
}
.btn-success:hover {
    color: $verde!important;
    background-color: $blanco!important;
    border-color: $verde!important;
    box-shadow: none !important;
}
.btn-success:focus {
    color: $verde!important;
    background-color: $blanco!important;
    border-color: $verde!important;
    box-shadow: none !important;
}

.btn-error {
    color: $blanco;
    background-color: $marron!important;
    border-color: $marron-claro!important;
}
.btn-error:hover {
    color: $marron!important;
    background-color: $blanco!important;
    border-color: $marron-claro!important;
    box-shadow: none !important;
}
.btn-error:focus {
    color: $marron!important;
    background-color: $blanco!important;
    border-color: $marron-claro!important;
    box-shadow: none !important;
}


.btn-sm{
    border-radius: .19rem !important;
    font-size: .75rem !important;
    line-height: 1.125 !important;
    font-weight: bold !important;
    font-family: 'normal', Lato !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.btn-xs{
    border: 0.10rem solid transparent !important;
    border-radius: .19rem  !important;
    padding: 0.3rem 1rem !important;
    font-size: .75rem !important;
    font-weight: bold !important;
    font-family: 'normal', Lato !important;
    justify-content: center !important;
}

.btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-icon img {
    width: 1rem !important;
    height: 1rem !important;
}
.icon-btn-1{
    display: initial;
}
.icon-btn-2{
    display: none;
}
.btn-icon:hover {

    .icon-btn-1{
        display: none;
    }
    .icon-btn-2{
        display: initial;
    }

}


@media (max-width: $sm) {
    .btn-action {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.4rem;

        .fas {
            margin: auto;
        }
    }

    .btn-xs{
        padding: 0.2rem 0.4rem !important;
    }
}

.btn-redes-sociales{
    width: 3.5rem;
    cursor: pointer;
}

.btn-tooltips{
    color: #FFFFFF;
    background-color: #1446A0;
    border-radius: 3px;
    height: 1rem;
    width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.fa-sm{
    font-size: 0.875em !important;
}