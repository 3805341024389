@import "../configs/color.css";
@import "../configs/breakpoints.css";

.card {
    background-color: #fff;
    border: none !important;
    border-radius: 0.3rem;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
}

.card-borde {
    border: 1px solid #BDBDBD !important;
}

.card .card-content-form{
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    padding: 3.5rem;
    @media (max-width: $md) {
        padding: 1rem;
    }

}

.card .card-content{
    background-color: white;
    border-radius: 1rem;
}

.card-post-header {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0 1rem;
}

.card-post-texto {
    display: flex;
    flex-direction: row;
    margin: 0.5rem 2.5rem;
}

.card-post-reacciones {
    display: flex;
    flex-direction: row;
    margin: 0.5rem 2.5rem;
}

.card-post-actions {
    display: flex;
    flex-direction: row;
    flex: 1;
    border-top: 1px solid $gris-claro;
}

.btn-post-vermas {
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    text-decoration: underline;
    color: $celeste-oscuro;
}

.btn-post-vermas:hover {
    color: $azul-oscuro;
}

.btn-post-action {
    background-color: transparent !important;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
}

.btn-post-action:hover, .btn-post-action.active {
    outline: none !important;
    background-color: $gris-claro !important;
}

.card-intereses-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $gris-claro;
}

.card-row-interes {
    display: flex;
    flex-direction: row;
    margin-bottom: 0rem;
    border-bottom: 2px solid $gris-claro;
}

.card-intereses-body {
    display: flex;
    flex-direction: column;
    margin: 0rem 0rem 0rem 0rem;
}

.card-intereses-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0.8rem 1rem 0.8rem 1rem;
}

.card-intereses-logo {
    border-radius: 50%;
    border: 2px solid #363C3F;
}

.btn-intereses {
    border: 0.13rem solid transparent !important;
    border-radius: .19rem  !important;
    padding: 0.4rem 1rem !important;
    font-size: .75rem !important;
    line-height: 1.125 !important;
    font-weight: bold !important;
    font-family: 'normal', Lato !important;
    justify-content: center !important;
    z-index: 1000 !important;
    text-align: center;
}

.borde-top {
    border-top: 2px solid $gris-claro;
}

.borde-bottom {
    border-bottom: 2px solid $gris-claro;
}

@media (max-width: $md) {
    .card-post-header {
        padding: 0 0.2rem;
    }

    .card-post-texto, .card-post-reacciones {
        margin: 0.5rem 1rem;
    }
}

.btn-post-action:focus{
    border: none !important;
    outline: none !important;
}
.me__gusta[wobble='1'] {
    animation: wobble 1.5s 1;
    }

@keyframes wobble {
    12.5% {
        transform: rotate(15deg);
    }
    25% {
        transform: rotate(-30deg);
    }
    37.5% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(-30deg);
    }
    62.5% {
        transform: rotate(15deg);
    }
    75% {
        transform: rotate(-30deg);
    }
    87.5% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.card-post-imagen{
    height: 22rem;
    @media (max-width: $lg){
        height: 20rem;
    }

    @media (max-width: $md){
        height: 18rem;
    }
    @media (max-width: $sm) {
        height: 15rem;
    }
}
.card__img__container{
    height:15rem;
    @media (max-width: $sm){
        height: 12rem;
    }
}
.card__img{
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    overflow: hidden;
   /*  background-color: #BDBDBD;
    border: 1px solid;
    border-radius: 0.5rem; */
}
.card__img img{
    position: absolute;
    width: 100%;
  /*   max-width: 100%;  */
    top: -9999px; 
    bottom: -9999px; 
    left: -9999px; 
    right: -9999px; 
    margin: auto;
   /*  border-radius:0.5rem; */
    
}