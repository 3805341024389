.main-navbar, .navbar{
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
}

.navbar {
    border-bottom: 2px solid $gris-claro;
}

.ant-menu {
    width: 100% !important;
    margin-top: 1rem;
    border-right: none !important;
}

.icon-menu1 {
    display: initial;
    width: 18px;
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.icon-menu2 {
    display: none;
    width: 18px;
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.ant-menu-submenu-arrow {
    display: none;
}

.ant-menu-submenu-title > span {
    font-size: 16px;
    color: $azul-gris-oscuro;
}

.ant-menu-item > span {
    font-size: 16px;
    color: $azul-gris-oscuro;
}

.ant-menu-sub {
    padding-left: 2rem !important;
    background-color: $gris-claro !important;
}

.ant-menu-item-only-child {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.sub-menu-barra {
    border-radius: 2px;
    height: 2rem;
    width: 0.4rem;
    position: absolute;
    left: 10%;
    z-index: 8;
    background-color: $marron-claro;
}

.ant-menu-item-selected {
    background-color: $gris-claro !important;
    color: $azul-gris-oscuro;
}

.ant-menu-item-only-child:hover {
    color: $azul-gris-oscuro;
}

.ant-menu-item-selected::after {
    display: none !important;
}

.ant-menu-item:hover, .ant-menu-item-selected, .ant-menu-submenu:hover {
    background-color: $marron-claro !important;
    /* span {
        color: white !important;
    } */

    .icon-menu1 {
        display: none;
    }
    .icon-menu2 {
        display: initial;
    }
}

.ant-menu-submenu {
    .ant-menu-item {
        padding-left: 0.2rem !important;
        /* margin-left: 2rem; */
        border-left: 4px solid $gris-claro !important;
    }
    .ant-menu-item:hover, .ant-menu-item-selected {
        border-left: 4px solid $marron-claro !important;
        background-color: $gris-claro !important;
    }
}

.perfil-img {
    height: 6rem;
    width:  6rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% auto;
}

.perfil-nombre {
    font-size: 20px;
    max-width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 0 0.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $azul-gris-oscuro;
}

.open .menu-fondo {
    width: 100%;
    height: 100vh;
    /* background-color: transparent; */
    background: #E6E6E6;
    opacity: 0.75;
    z-index: 1;
    position: absolute;
    top: 0%;
    left: 100%;
}

@media (max-width: $md) {
    .perfil-img {
        height: 5.5rem;
        width:  5.5rem;
    }
    .main-sidebar {
        background-color: $azul-oscuro !important;
        top: 0 !important;
        height: 100vh !important;
    }

    .main-navbar {
        height: 35%;
    }

    .main-navbar, .navbar {
        background-color: $azul-oscuro !important;
    }

    .perfil-nombre {
        color: white;
    }

    .ant-menu {
        background-color: $azul-oscuro !important ;
    }

    .icon-menu1 {
        display: none;
    }
    .icon-menu2 {
        display: initial;
    }

    .ant-menu-item span {
        color: white !important;
    }

    .ant-menu-submenu-title span {
        color: white !important;
    }

    .ant-menu-sub {
        background-color: white !important;
    }

    .ant-menu-item:hover, .ant-menu-item-selected, .ant-menu-submenu:hover {
        background-color: white !important;
        span {
            color: $azul-gris-oscuro !important;
        }

        .icon-menu1 {
            display: initial;
        }
        .icon-menu2 {
            display: none;
        }
    }

    .ant-menu-submenu:hover {
        .ant-menu-submenu-title {
            border-bottom: 2px solid $azul-gris-oscuro;
        }
    }

    .ant-menu-submenu {
        .ant-menu-item {
            border-left: 4px solid white !important;
        }

        .ant-menu-item:hover, .ant-menu-item-selected {
            border-left: 4px solid $marron-claro !important;
            background-color: white !important;
        }
    }

}

@media (max-width: 360px) {
    .perfil-img {
        height: 4rem;
        width:  4rem;
    }
}
