.login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5rem;
}

.login-panel {
    /* width: 90vw; */
    /* height: 80vh; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: transparent;
}

.login-card-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    background-color: #0A1F40;
    opacity: 0.75;
}

.login-card-cover {
    display: flex;
    flex-direction: row;
    flex: 3;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo-login {
    width: 12rem;
}

.login-link-action {
    color: #BE7D3C;
}

.login-link-action:hover {
    color: #AF5555;
}

.login-form {

    .invalid-feedback {
        font-weight: bold;
    }

    .form-control {
        border-radius: 0 !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-bottom: 3px solid white !important;
        color: white !important;
        font-weight: bold;
        background-color: transparent !important;
    }

    .form-control.is-invalid {
        border-bottom: 3px solid #AF5555 !important;
    }
}

.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}

@media only screen and (max-width: 576px) {
    .login-container {
        padding: 1.5rem !important;
    }
}

@media only screen and (max-width: 768px) {
    .login-card-cover {
        display: none !important;
    }
}
